<template>
  <div class="steps text-left">
    <div class="steps__title">{{ $tm('onboarding.kyc.title') }}</div>
    <div class="font-bold text-lg text-grey-2 mb-11 block md:hidden">
      {{ $tm('onboarding.kyc.title_mobile') }}
    </div>

    <!-- <div class="steps__subtitle hidden md:block">
      {{$tm('onboarding.kyc.subtitle')}}
    </div> -->
    <div
      v-for="(line, lineNumber) of $t('onboarding.kyc.subtitle').split('\n')"
      v-bind:key="lineNumber"
      class="steps__subtitle hidden md:block"
    >
      <div v-html="line"></div>
    </div>
    <div class="steps__subtitle text-grey-2 block md:hidden">
      {{$tm('onboarding.kyc.subtitle_mobile')}}
    </div>
    <!-- <div class="steps__investor mb-6" @click="next('passport')">
      <div class="flex items-center">
        <div
          class="
            steps__investor__icon steps__investor__icon--kyc
            bg-gradient-green--desktop
          "
        >
          <img src="@/assets/ico/onboarding/passport.svg" alt="" />
        </div>
        <div>
          <div class="steps__investor__title">
            {{ $tm('onboarding.kyc.passport') }}
          </div>
        </div>
      </div>
      <div>
        <img src="@/assets/svg/chev-right-2.svg" alt="" />
      </div>
    </div> -->
    <div class="steps__investor mb-3" @click="next('IDENTITY_PROOF')">
      <div class="flex items-center">
        <div
          class="
            steps__investor__icon steps__investor__icon--kyc
            bg-gradient-green--desktop
          "
        >
          <img src="@/assets/ico/onboarding/driving.svg" alt="" />
        </div>
        <div>
          <div class="steps__investor__title">
            {{ $tm('onboarding.kyc.identity') }}
          </div>
        </div>
      </div>
      <div>
        <img src="@/assets/svg/chev-right-2.svg" alt="" />
      </div>
    </div>
    <div class="w-full text-left text-grey-4 text-13">
      {{ $t('onboarding.kyc.popup.call_to_action_follow') }}
      <span class="orange-link" @click="showPopup">
        {{ $t('onboarding.kyc.popup.call_to_action_link') }}
      </span>
      {{ $t('onboarding.kyc.popup.call_to_action_title') }}
    </div>
    <div class="hidden mb-20">
      <div class="indented-custom-bullet" id="popup-content-kyc">
        <ul>
          <li
            v-for="item in $tm('onboarding.kyc.popup.list')"
            v-bind:key="item"
            class="mb-3 pl-4 text-sm text-grey-3"
          >
            {{ item }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
export default {
  props: {},
  components: {},
  emits: ['nextKyc'],
  setup(props, { emit }) {
    const i18n = useI18n({ useScope: 'global' })
    const store = useStore()

    function next(value) {
      store.dispatch('setKycType', value)
      emit('nextKyc', value)
    }

    function showPopup() {
      const content = document.getElementById('popup-content-kyc')
      const popup = {
        title: i18n.t('onboarding.kyc.popup.title'),
        align: 'left',
        side: true,
        content: content.outerHTML
      }
      store.dispatch('setPopup', popup)
    }

    return {
      next,
      showPopup
    }
  }
}
</script>
